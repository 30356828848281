<template>
  <main>
    <Row :gutter="15">
      <Col :span="17">
        <ButtonGroup shape="circle">
          <Button icon="md-refresh" @click="loadData">刷新</Button>
          <Button
            icon="md-add-circle"
            @click="create"
            v-if="Auth([ 'QuestionSubject', 'create' ])"
          >添加</Button>
          <Button
            icon="md-download"
            @click="exportFile"
            v-if="Auth([ 'QuestionSubject', 'export' ])"
          >导出</Button>
        </ButtonGroup>
      </Col>
    
    </Row>
    <Divider/>
    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>
  </main>
</template>

<script>
export default {
  data() {
    return {
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "分类",
          key: "category",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h("span", params.row.category.name);
          }
        },
         {
          title: "科目",
          key: "name",
          align: "center",
          sortable: "custom",
          minWidth: 140,
        },
        {
          title: "题目数量",
          key: "questions_count",
          sortable: "custom",
          align: "center",
          minWidth: 100,
          render: (h, params) => {
            return h("span", params.row.questions_count);
          }
        },
        {
          title: "排序",
          minWidth: 90,
          key: "weight",
          align: "center",
          sortable: "custom",
          render: (h, params) => {
            var html = [h("span", params.row.weight)];

            if (params.row.stick) {
              html.push(
                h("Icon", {
                  props: {
                    type: "md-arrow-round-up"
                  },
                  class: "text-primary ml-xs"
                })
              );
            }

            return h("div", html);
          }
        },
        {
          title: "状态",
          minWidth: 90,
          key: "status",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("Icon", {
              props: {
                type: params.row.status
                  ? "ios-checkmark-circle"
                  : "ios-remove-circle"
              },
              class: params.row.status ? "text-green" : "text-red"
            });
          }
        },
        {
          title: "创建时间",
          minWidth: 140,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        {
          title: "操作",
          minWidth: 110,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (this.Auth(["QuestionSubject", "update"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "success",
                    size: "small",
                    icon: "md-create"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.update(params.row);
                    }
                  }
                })
              );
            }

            // if (this.Auth(["QuestionSubject", "delete"])) {
            //   html.push(
            //     h("Button", {
            //       props: {
            //         type: "error",
            //         size: "small",
            //         icon: "md-trash"
            //       },
            //       class: "mg-lr-xs",
            //       on: {
            //         click: () => {
            //           this.remove(params.row);
            //         }
            //       }
            //     })
            //   );
            // }

            return h("div", html);
          }
        }
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
       
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http
        .get("/questionSubject/list", { params: params })
        .then(res => {
          this.total = res.total;
          this.data = res.data;
        });
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    create() {
      this.$router.push("/question-subject/create");
    },
    update(item) {
      this.$router.push("/question-subject/update/" + item.id);
    },
    remove(item) {
      this.$Modal.confirm({
        title: "确定要删除该科目吗？",
        content: item.name,
        onOk: () => {
          this.$http
            .post("/questionSubject/delete/id/" + item.id)
            .then(res => {
              this.loadData();
            });
        }
      });
    },
    exportFile() {
      let params = {
        
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit,
        token: window.localStorage.getItem("token")
      };

      let url = this.$http.defaults.baseURL + "/questionSubject/export?";

      window.open(buildURL(url, params));
    }
  }
};
</script>